import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const links = [
  { title: "Color Hex Color Codes", url: "https://www.color-hex.com/" },
];

export const LinksIndexPage = () => {
  return (
    <Layout>
      <SEO title="Links" />
      <Root>
        <summary>
          <h3>🔖A few bookmarks</h3>
          <ul>
            {links.map((link) => {
              const favicon = `https://www.google.com/s2/favicons?domain=${link.url}`;
              return (
                <li key={link.title}>
                  <Bookmark href={link.url}>
                    <Favicon src={favicon} />
                    {link.title}
                  </Bookmark>
                </li>
              );
            })}
          </ul>
        </summary>
      </Root>
    </Layout>
  );
};

export default LinksIndexPage;

const Root = styled.div`
  display: flex;
`;

const Bookmark = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Favicon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;
